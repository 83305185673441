<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    @input="onModalChanged"
  >
    <v-card>
      <v-card-title>
        <span
          v-t="'payment.cancelRecurringPayments'"
          class="headline"
          data-cy="payment-cancel-modal"
        />
        <v-spacer />
        <v-icon
          data-cy="payment-cancel-message-close-button"
          @click="closeModal()"
        >
          {{ closeIcon }}
        </v-icon>
      </v-card-title>

      <v-divider />

      <div v-if="loadingState">
        <v-card-text class="text-center">
          <h3 v-t="'payment.weAreCancelingSelectedRecurringPayment'" />
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
          />
        </v-card-text>
      </div>
      <div v-if="loadedState">
        <v-card-text
          class="text-center"
          data-cy="payment-cancel-done-message"
        >
          <h3 v-t="'payment.recurringPaymentCancelled'" />
          <p v-t="'payment.cancellationOfTheRecurringPaymentHasBeenProcessedSuccessfully'" />
          <p>
            <span v-t="'payment.pleaseDoNotForgetToManuallyRenewYourSubscriptionBeforeContractExpirationDate'" />
            <!-- eslint-disable-line vue-i18n/no-raw-text -->(
            <span
              v-t="'payment.youWillFindItInSection'"
              class="text-lowercase"
            />
            <router-link
              depressed
              :to="{ name: productDetailsRoute }"
              data-cy="link-quotations"
            >
              {{ $t('payment.productDetailsLink') }}
            </router-link>
            <!-- eslint-disable-line vue-i18n/no-raw-text -->).
          </p>
        </v-card-text>
        <v-divider />
        <v-card-text class="text-center">
          <p>
            {{ $t('payment.asAlwaysWeAreAvailableForAnyQuestionsOnTheRegularChannels') }}
          </p>
          <div class="ma-auto d-table">
            <core-element-manager-contacts class="text-left" />
          </div>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  ROUTE_PRODUCT_DETAILS
} from '@/constants/routeNames'
import { CLOSE_ICON } from '@/constants/app'

export default {
  data () {
    return {
      dialog: true,
      productDetailsRoute: ROUTE_PRODUCT_DETAILS,
      closeIcon: CLOSE_ICON
    }
  },
  computed: {
    ...mapState('recurringPayments', ['loading']),
    loadingState () {
      return this.loading === 'loading'
    },
    loadedState () {
      return this.loading === 'loaded'
    }
  },
  methods: {
    ...mapMutations('recurringPayments', ['setLoading']),
    onModalChanged (payload) {
      if (!payload) {
        this.setLoading(false)
      }
    },
    closeModal () {
      this.onModalChanged(false)
      this.dialog = false
    }
  }
}
</script>
